<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Team Attendance</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'my-team'}">
                        <span>My Team</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Attendance</li>
                <li class="breadcrumb-item active" aria-current="page">{{employee.name}} [IES-{{employee.id}}]</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="8">
                    <b-form-group
                        label="Employee"
                        label-cols-sm="1"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-size:1rem !important;"
                    >
                        <b-input-group size="md row" >
                            <div class="col-md-4 ">
                                <EmployeeList :reporting_to="employeeReportingto" @emp_id="getEmpId" :employee_id="urlBody.employee_id"></EmployeeList>
                            </div>

                            <div class=" col-md-4 row">
                                <label
                                    class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                                >Year</label>
                                <div class="col-lg-9 col-md-10 col-sm-12">
                                    <b-form-select
                                        v-model="urlBody.year"
                                        :options="years"
                                        disabled-field="notEnabled"
                                    ></b-form-select>
                                </div>
                            </div>
                            <b-button
                                :disabled="!filter"
                                @click="onFiltered"
                                class="btn btn-primary form-group col-md-1"
                                variant="primary"
                            >Filter</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(month)="row">
                    {{getMonthName(row.item.month)}}
                </template>
            </b-table>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            urlBody: {
                sort_by: "",
                order: "",
                year: new Date().getFullYear(),
                employee_id: ""
            },
            api_error: "",
            sortBy: "year",
            sortDesc: false,
            fields: [
                { key: "year", label:"Year", sortable: true},
                { key: "month", label: "Month", sortable: true},
                { key: "leave_days", label: "Leaves" },
                { key: "lop_days", label: "LOP" },
                { key: "night_shift_days", label: "Night Shifts" },
                { key: "worked_days", label: "Present days" },
            ],
            items: null,
            filter: "",
            access: false,
            employeeReportingto:1,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            employee: {
                id:"",
                name:"",
            },
            years: [],
            total_years:2,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_VIEW_MY_TEAM");
        this.getYears();
        this.onPageLoad();
        this.employeeReportingto = (this.hasAnyPermission(['CAN_VIEW_ALL_TEAMS'])) ? 0 : 1;
    },
    methods: {
        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmpId(id) {
            this.filter = id;
        },

        onFiltered() {
            var query = {
                employee_id :this.filter,
                year : this.urlBody.year
            };
            this.employee.id = query.employee_id;
            this.filter = this.employee.id;
            this.getEmployee(this.employee.id);
            this.getEmployeeAttendance(query);
        },

        getEmployee: function(value) {
            let query = this.getAuthHeaders();
            let data = {
                query: value
            };
            query['params']= this.deleteEmptyKeys(data)
            this.axios
                .get(this.$api.get_search_employee, query)
                .then(response => {
                    this.employee.name = response.data.data[0].full_name;
                })
                .catch(err => {
                    console.error(err);
                });
        },

        onPageLoad() {
            let api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.sort_by = api_params.sort_by
                ? api_params.sort_by
                : this.urlBody.sort_by;
            this.urlBody.order = api_params.order
                ? api_params.order
                : this.urlBody.order;              
            this.urlBody.year = api_params.year
                ? api_params.year
                : this.urlBody.year;
            this.urlBody.employee_id = api_params.employee_id
                ? api_params.employee_id
                : this.urlBody.employee_id;
            this.employee.id = this.$route.query.employee_id;
            this.filter = this.employee.id;
            this.getEmployee(this.employee.id);
            this.getEmployeeAttendance(this.deleteEmptyKeys(this.urlBody));
        },

        getEmployeeAttendance: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = params;
            var emp_id = this.employee.id;
            if(params.employee_id) {
                emp_id = params.employee_id
            }
            query["params"]["employee_id"]= emp_id;
            this.axios
                .get(this.$api.get_team_attendance, query)
                .then(response => {
                    this.items = response.data.data;
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router.push({
                        path: this.$route.params[0],
                        query: this.deleteEmptyKeys(params)
                    }).catch(()=>{});
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            this.urlBody.order = ctx.sortDesc ? "asc" : "desc";
            this.urlBody.sort_by = ctx.sortBy;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{})
            this.getEmployeeAttendance(urlBody);
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
